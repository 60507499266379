<template>
 <div>
  <svg id="Laag_1" style="enable-background:new 0 0 183 183;" version="1.1" viewBox="0 0 183 183" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
   <defs>
    <linearGradient gradientUnits="userSpaceOnUse" id="company_00000092438485934053034350000006733690869833712041_" x1="122.0882" x2="12.2122" y1="69.6445" y2="161.8413">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <rect :fill="background" height="183" width="183"/>
   <path d="M147.5,143.1h-3.6V84.9  c0-1.5-0.9-2.8-2.3-3.2L109.6,71v78.9h37.9c1.9,0,3.4-1.5,3.4-3.4S149.4,143.1,147.5,143.1z M137,143.1h-6.8v-13.7h-6.8v13.7h-6.8  V126c0-1.9,1.5-3.4,3.4-3.4c0,0,0,0,0.1,0h13.7c1.9,0,3.4,1.5,3.4,3.4c0,0,0,0,0,0.1C137,126,137,143.1,137,143.1z M133.6,115.7  h-13.7c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4l0,0h13.7c1.9,0,3.4,1.5,3.4,3.4C137,114.2,135.5,115.7,133.6,115.7z   M133.6,102h-13.7c-1.9,0-3.4-1.5-3.4-3.4s1.5-3.4,3.4-3.4l0,0h13.7c1.9,0,3.4,1.5,3.4,3.4S135.5,102,133.6,102z M100.6,53.8  L45.8,33.3c-1.8-0.7-3.8,0.3-4.4,2.1c-0.1,0.4-0.2,0.8-0.2,1.2v106.5h-3.6c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4h65.2V57.1  C102.8,55.6,101.9,54.4,100.6,53.8z M68.5,143.1H54.9v-17.1c0-1.9,1.5-3.4,3.4-3.4c0,0,0,0,0.1,0h10.3L68.5,143.1L68.5,143.1z   M65.1,115.7h-6.8c-1.9,0-3.4-1.5-3.4-3.4s1.5-3.4,3.4-3.4l0,0h6.8c1.9,0,3.4,1.5,3.4,3.4S67,115.7,65.1,115.7z M65.1,102h-6.8  c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4l0,0h6.8c1.9,0,3.4,1.5,3.4,3.4C68.5,100.5,67,102,65.1,102z M65.1,88.3h-6.8  c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4l0,0h6.8c1.9,0,3.4,1.5,3.4,3.4C68.5,86.8,67,88.3,65.1,88.3z M65.1,74.6h-6.8  c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4l0,0h6.8c1.9,0,3.4,1.5,3.4,3.4C68.5,73.1,67,74.6,65.1,74.6z M89.1,143.1H75.4v-20.5  h10.3c1.9,0,3.4,1.5,3.4,3.4c0,0,0,0,0,0.1V143.1L89.1,143.1z M85.7,115.7h-6.8c-1.9,0-3.4-1.5-3.4-3.4s1.5-3.4,3.4-3.4h6.8  c1.9,0,3.4,1.5,3.4,3.4S87.5,115.7,85.7,115.7z M85.7,102h-6.8c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4h6.8  c1.9,0,3.4,1.5,3.4,3.4C89.1,100.5,87.5,102,85.7,102z M85.7,88.3h-6.8c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4h6.8  c1.9,0,3.4,1.5,3.4,3.4C89.1,86.8,87.5,88.3,85.7,88.3z M85.7,74.6h-6.8c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4h6.8  c1.9,0,3.4,1.5,3.4,3.4C89.1,73.1,87.5,74.6,85.7,74.6z" id="company" style="fill:url(#company_00000092438485934053034350000006733690869833712041_);"/>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>